<template>
  <div>
    <div class="d-flex justify-content-center">
      <h4>Reserva</h4>      
    </div>

    <hr class="mb-4" />
    <div class="row">
      <div class="col-md-6">
        <h4 class="mb-3 text-muted">Selecione a Turma</h4>
        <div class="row">
          <div class="col-md-12 mb-3">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
                v-for="group in groups"
                :key="group.id"
                v-model="reserve.group_id"
                :aria-describedby="ariaDescribedby"
                :value="group.id"
                @change="change_group"
                :disabled="disabled"
                >{{ group.group }}</b-form-radio
              >
            </b-form-group>
          </div>
        </div>
      </div>     
      <div class="col-md-6">
        <h4 class="mb-3 text-muted">Selecione o Período</h4>
        <div class="row">
          <div class="col-md-12 mb-3">
            <div class="form-check" v-if="periods.length > 0">
              <div v-for="period in periods" :key="period.id">
                <input
                  v-model="reserve.period_id"
                  class="form-check-input"
                  type="radio"
                  :id="`period-${period.id}`"
                  :value="period.id"
                  @change="change_period"
                />
                <label class="form-check-label" :for="`period-${period.id}`">
                  {{ period.period }}
                </label>
              </div>
            </div>
            <div v-else>Nenhum periodo listado</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      reserve: {
        period_id: "",
        group_id: "",
        plan_id: "",
        current_year: "",
      },
    };
  },
  computed: {
    groups() {
      return this.$store.state.reEnrolls.groups;
    },
    periods() {
      return this.$store.state.reEnrolls.periods;
    },
  },
  methods: {
    async change_group() {
      this.disabled = true
      this.reserve.period_id = "";
      await this.$store.dispatch("http_get", {
        url: `/plan/group/${this.reserve.group_id}?active=1`,
        vuex: "reEnrolls/periods",
      });
      this.disabled = false
      // this.$store.dispatch("reEnrolls/get_periods", this.reserve.group_id);
    },
    async test() {    
      const unityId = this.$route.params.unityId;
  
      const response = await this.$store.dispatch("http_post", {
        url: `/token-unity`,
        data: unityId,
      });
      console.log(response)
    },
    change_period() {
      const selectedPeriod = _.filter(this.periods, (period) => {
        return period.id == this.reserve.period_id;
      });
      this.reserve.plan_id = selectedPeriod[0].plan_id;
      this.reserve.current_year = selectedPeriod[0].current_year;

      return selectedPeriod;
    },
    getModel() {
      return this.reserve;
    },
  },
  mounted(){
    this.test()
  }
};
</script>

<style>
</style>